Input[type="text"] {
    margin-left: 50px;
  }
  .avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
 #employment_type {
  margin-left: 50px;
  width: 340px;
 }
 #qualification {
  margin-left: 50px;
  width: 340px; 
 }
 Input[type="number"] {
  margin-left: 50px;
}