.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }

  .modalStyle {
    margin-top: 25px;
  }
  
  
  .labelStyle {
    text-align: right;
    margin-right: 15px;
  }

  .img-wrap {
    position: relative;
    display: inline;
    float: auto;
  }

  .img-wrap .close {
      position: absolute;
      top: -65px;
      /* left: 108px; */
      right: -50px;
      z-index: 100;
  }

  .img-wrap img {
    width: 41%;
    height: 148px;
    margin-left: 10px;
  }
   
  .inputStyle{
    margin-left: -47px;
    width:267px;
  }

  .inputStyle textarea{
    margin-left: 50px;
  }

  .inputStyle input[type="file"]{
    margin-left: 50px;
  }

  .extraclass input[type="file"]{
   visibility: hidden;
  }

  .clearfix{
    display: inline;
  }

  .pdfStyle{
    margin-left: 228px;
  }
  .publishedStatus{
    color:#FF0000;
  }
  .topicForm .errorMsg {
    margin-left: 3px !important;
  }

  @media only screen and (min-width : 320px) {
    .ant-card ant-card-bordered{
      width: 70%;
    }
  }
