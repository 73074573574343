@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
} */

.home,
.reports,
.products,
.team,
.reports {
  display: flex;
  height: 100%;
  align-items: left;
  /* justify-content: center; */
  font-size: 3rem;
  margin-top: 5px;
  background-color: #fff;
}

.container {
  display: flex;
}
.others {
  flex: 4 1;
}
.selectedItem {
  color: #40a9ff !important;
  border-color: #40a9ff !important;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family:'R'; */
  /* color: white; */
}
.errorMsg {
  color: red
}
input[type="file"] {
  margin-left: 50px;
}
button[type="submit"] {
  margin-left: -7px;
}
.OverviewStyle {
  width: 100%;
  height: 100%;
  text-align: center;
}

.OverviewStyle h3 {
  font-size: 100px;
  font-weight: bolder;
  color: rgb(49, 24, 71);
}

.OverviewStyle h4 {
  font-size: 40px;
  font-weight: lighter;
  color: black;
}

.OverviewStyle h5 {
  font-size: 20px;
  font-weight: bold;
  color: black;
}
.OverviewStyle h6 {
  font-size: small;
  font-weight: normal;
  color: grey;
}

.image {
  width: 100%;
  height: 100%;
  text-align: center;
}
*{
  margin: 0px;
  border:none;
  padding: none;
}
.main {
  background-color: hsl(180, 23%, 73%);
  width: 350px;
  padding: 10px;
  flex-direction: row;
  -ms-flex-align: center;
  margin: 7em auto;
  border-radius: 0.5em;
  box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
}

.sign {
  padding-top: 20px;
  text-align: center;
  color: #080708;
  font-weight: bold;
  font-size: 20px;
}

.un {
  width: 100%;
  color: rgb(38, 50, 56);
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  outline: none;
  box-sizing: border-box;
  border: 2px solid rgba(0, 0, 0, 0.02);
  margin-bottom: 5px;
  text-align: left;
}

form.form1 {
  padding-top: 40px;
}

.pass {
  width: 100%;
  color: rgb(38, 50, 56);
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  /* background: rgba(136, 126, 126, 0.04); */
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  outline: none;
  box-sizing: border-box;
  border: 2px solid rgba(0, 0, 0, 0.02);
  margin-bottom: 5px;
  text-align: left;
}

.un:focus,
.pass:focus {
  border: 2px solid rgba(0, 0, 0, 0.18) !important;
}

.submit {
  cursor: pointer;
  text-align: center;
  color: #fff;
  border: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

a {
  text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
  text-decoration: none;
}
Input[type="text"]{
  margin-left: auto;
}

@media (max-width: 600px) {
  .main {
    border-radius: 0px;
  }
}




.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.modalStyle {
  margin-top: 25px;
}

.labelStyle {
  text-align: right;
  margin-right: 15px;
}

.spinner {
  margin: 20px 0;
  margin-bottom: 20px;
  text-align: center;
}
#advisory {
  margin-left: 21px;
  margin-top: 30px;
}
.specStyle{
  margin:10px;
}

.specCard{
  width: 100%;
}
.specSaveBtn{
  margin-left:29px;
}

.advisoryPhoto{
  width: 70px;
  height: 70px;
}

.advisorySaveBtn{
  margin-left:-47px;
}

.advisoryPara{
  color: grey;
  font-size: 10px;
}

.advisoryImg{
  width: 38px;
  height: 38px;
  margin-top: -23px;
  margin-left:215px;
}

.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }

  .modalStyle {
    margin-top: 25px;
  }
  
  
  .labelStyle {
    text-align: right;
    margin-right: 15px;
  }

  .img-wrap {
    position: relative;
    display: inline;
    float: auto;
  }

  .img-wrap .close {
      position: absolute;
      top: -65px;
      /* left: 108px; */
      right: -50px;
      z-index: 100;
  }

  .img-wrap img {
    width: 41%;
    height: 148px;
    margin-left: 10px;
  }
   
  .inputStyle{
    margin-left: -47px;
    width:267px;
  }

  .inputStyle textarea{
    margin-left: 50px;
  }

  .inputStyle input[type="file"]{
    margin-left: 50px;
  }

  .extraclass input[type="file"]{
   visibility: hidden;
  }

  .clearfix{
    display: inline;
  }

  .pdfStyle{
    margin-left: 228px;
  }
  .publishedStatus{
    color:#FF0000;
  }
  .topicForm .errorMsg {
    margin-left: 3px !important;
  }

  @media only screen and (min-width : 320px) {
    .ant-card ant-card-bordered{
      width: 70%;
    }
  }

.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }

  .modalStyle {
    margin-top: 25px;
  }

  .labelStyle {
    text-align: right;
    margin-right: 15px;
  }

  .errorMsg{
    margin-left: 50px;
  }
  
  .playerProfilePic_home_tile{
    margin-left: 50px;
    width: 128px;
    height: 128px;
  }
Input[type="text"] {
    margin-left: 50px;
  }
  .avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
 #employment_type {
  margin-left: 50px;
  width: 340px;
 }
 #qualification {
  margin-left: 50px;
  width: 340px; 
 }
 Input[type="number"] {
  margin-left: 50px;
}

.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }
  
  .modalStyle {
    margin-top: 25px;
  }
  
  .labelStyle {
    text-align: right;
    margin-right: 15px;
  }
  
  .spinner {
    margin: 20px 0;
    margin-bottom: 20px;
    text-align: center;
  }

  #advisory {
    margin-left: 21px;
    margin-top: 30px;
  }
  
  .playerProfilePic_home_tile{
    margin-left: 50px;
    width : 128px;
    height : 128px;
  }

  #btn{
    margin-left: 21px;
  }

  .errorMsg {
    margin-left: 50px !important;
  }
.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }

  .modalStyle {
    margin-top: 25px;
  }

  .labelStyle {
    text-align: right;
    margin-right: 15px;
  }

  .spinner {
    margin: 20px 0;
    margin-bottom: 20px;
    text-align: center;
  }
  .dropdown-content .panel-content {
    padding-left:50px;
  }
  .multi-select {
    margin-left: 0px;
  }
.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }
  
  .modalStyle {
    margin-top: 25px;
  }
  
  .labelStyle {
    text-align: right;
    margin-right: 15px;
  }
  
  .spinner {
    margin: 20px 0;
    margin-bottom: 20px;
    text-align: center;
  }
  #advisory {
    margin-left: 21px;
    margin-top: 30px;
  }
.modalStyle {
    margin-top: 25px;
  }
  .configStyle{
    margin-left:30px;
  }
  .configSaveBtn{
   margin-left:34px;
  } 
.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .trigger:hover {
    color: #1890ff;
  }
  
  .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  
  .site-layout .site-layout-background {
    background: #fff;
  }
.sidebar-logo {
  position: relative;
  height: 64px;
  padding-left: 24px;
  overflow: hidden;
  line-height: 64px;
  background: #001529;
  transition: all 0.3s;
}

.sidebar-logo h1 {
  display: inline-block;
  margin: 0 0 0 5px;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  vertical-align: middle;
}

.sidebar-logo img {
  display: inline-block;
  height: 32px;
  width: 32px;
  vertical-align: middle;
}

.sidebar {
  width: 224px;
}

/* .ant-layout-sider-children {
  width:230px;
}

.ant-layout site-layout {
  margin-left: 3px;
} */

.main-container {
    grid-area: main;
    overflow-y: auto;
    padding: 20px 20px;
    color: rgba(255, 255, 255, 0.95);
  }
  
  .main-title {
    display: flex;
    justify-content: space-between;
  }
  
  .main-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    gap: 20px;
    margin: 15px 0;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px 15px;
    border-radius: 5px;
  }
  
  .card:first-child {
    background-color: #2962ff;
  }
  
  .card:nth-child(2) {
    background-color: #ff6d00;
  }
  
  .card-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .card-inner > .card_icon {
    font-size: 25px;
  }
  
  .charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 60px;
    height: 300px;
    place-items: center;
  }

  .chart-text {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    gap: 20px;
    margin: 15px 0;
    place-items: center;
  }
