.OverviewStyle {
  width: 100%;
  height: 100%;
  text-align: center;
}

.OverviewStyle h3 {
  font-size: 100px;
  font-weight: bolder;
  color: rgb(49, 24, 71);
}

.OverviewStyle h4 {
  font-size: 40px;
  font-weight: lighter;
  color: black;
}

.OverviewStyle h5 {
  font-size: 20px;
  font-weight: bold;
  color: black;
}
.OverviewStyle h6 {
  font-size: small;
  font-weight: normal;
  color: grey;
}

.image {
  width: 100%;
  height: 100%;
  text-align: center;
}