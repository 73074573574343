@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
} */

.home,
.reports,
.products,
.team,
.reports {
  display: flex;
  height: 100%;
  align-items: left;
  /* justify-content: center; */
  font-size: 3rem;
  margin-top: 5px;
  background-color: #fff;
}

.container {
  display: flex;
}
.others {
  flex: 4;
}
.selectedItem {
  color: #40a9ff !important;
  border-color: #40a9ff !important;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family:'R'; */
  /* color: white; */
}
.errorMsg {
  color: red
}
input[type="file"] {
  margin-left: 50px;
}
button[type="submit"] {
  margin-left: -7px;
}