.sidebar-logo {
  position: relative;
  height: 64px;
  padding-left: 24px;
  overflow: hidden;
  line-height: 64px;
  background: #001529;
  transition: all 0.3s;
}

.sidebar-logo h1 {
  display: inline-block;
  margin: 0 0 0 5px;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  vertical-align: middle;
}

.sidebar-logo img {
  display: inline-block;
  height: 32px;
  width: 32px;
  vertical-align: middle;
}

.sidebar {
  width: 224px;
}

/* .ant-layout-sider-children {
  width:230px;
}

.ant-layout site-layout {
  margin-left: 3px;
} */
